#root {
    height: max(100vh, calc(100% + env(safe-area-inset-top, 0px)));
    height: 100dvh;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  min-height: 100%;
  background: hsl(187, 15%, 96%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: '100%';
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

*:focus {
    outline: none;
}
