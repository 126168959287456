a{
    text-decoration: none;
    color: inherit;
}

ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.vbs-logo{
 background-image: 
 linear-gradient(transparent, transparent),
    url('static/images/vbs_logo_new_white.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
    width: 85%;
    max-width: 300px;
    height: 100%;
    max-height: 100px;
}

.vbs-logo.header{
    width: 135px;
    height: 65px;
}

.vbs-logo.primary{
    background-image: 
    linear-gradient(transparent, transparent),
    url('static/images/vbs_logo_new_primary.svg');
}