.footer-mobile-wrp{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 1em;
    flex: 0 0 auto;

    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #9E9E9E;
}

.footer-mobile-provider{
    padding-top:1em;
}

.footer-mobile-wrp a{
    color: #757575;
}